<!-- 인벤토리 리스트 -->
<template>
  <v-container
    id="InventoryList"
    fluid
    tag="section">
    <v-row
            align="center"
            justify="center">
      <v-col cols="12">
        <base-material-card
                color="green">
          <template v-slot:heading>
            <h1 class="display-2 font-weight-regular">
              <span v-if="response.media">
                {{ response.media.kor_name }} >
              </span>
              인벤토리 리스트
            </h1>
          </template>
          <v-card-text>
            <v-row justify="space-between">
              <v-btn color="default"
                     @click="beforePage">
                이전
              </v-btn>
              <v-btn color="secondary"
                     @click="createInventory">
                신규 등록
              </v-btn>
            </v-row>
            <v-divider class="mt-3" />
            <template v-if="resultInventory">
              <v-data-table
                      :headers="config.headers"
                      :items="resultInventory.media"
                      :items-per-page="Number(resultInventory.meta.perPage)"
                      item-key="key"
                      no-data-text="매체가 없습니다."
                      hide-default-footer>
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="text-center">{{item.id}}</td>
                    <td class="text-center"><a v-on:click="goRendering(item)" title=": 인벤토리 수정">{{ item.name }}</a></td>
                    <td style="text-align: center;">
                      <template v-if="item.deleted_at">
                        -
                      </template>
                      <template v-else>
                        <v-btn @click="goEdit(item)" small rounded color="secondary">수정</v-btn>
                      </template>
                    </td>
                    <td style="text-align: center;">
                      <template v-if="item.deleted_at">
                        -
                      </template>
                      <template v-else>
                        <v-btn @click="goRendering(item)" small rounded color="success">수정</v-btn>
                      </template>
                    </td>
                    <td style="text-align: center;">
                      <template v-if="item.deleted_at">
                        <v-btn small @click="restoreInventory(item)" color="default">OFF</v-btn>
                      </template>
                      <template v-else>
                        <v-btn small @click="deleteInventory(item)" color="warning">ON</v-btn>
                      </template>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-pagination
                      v-model="vModel.page"
                      :length="resultInventory.meta.lastPage"
                      :total-visible="10"
                      @input="paginationClick"
                      circle
                      class="justify-center mt-5"
                      next-icon="mdi-menu-right"
                      prev-icon="mdi-menu-left"/>
            </template>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
      <base-material-snackbar
              v-model="vModel.snackbar"
              :type="vModel.snackbarType"
              v-bind="{
        'top': true,
        'right': true
      }">
          <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
      </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { common } from '../../mixins/common.js'

export default {
  mixins: [ common ],
  created () {
    this.refresh()
  },
  data () {
    return {
      response: {
        media: null,
        list: null,
        listDelete: null,
      },
      vModel: {
        page: 1,
        snackbar: false,
        snackbarType: 'warning',
        errorMessage: null,
      },
      config: {
        headers: [
          { text: 'ID', value: 'id', align: 'center' },
          { text: '이름', value: 'name', align: 'center' },
          { text: '정보 수정', value: 'edit', align: 'center' },
          { text: '렌더링 관리', value: 'rendering', align: 'center' },
        ]
      }
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    /* 페이지 로드시 */
    refresh () {
      let send = {
        page: 1
      }
      if (this.$route.query.page) {
        send.page = this.$route.query.page
        this.vModel.page = Number(this.$route.query.page)
      }
      this.config.headers.push({ text: '활성화 상태', value: 'status', align: 'center' })
      this.$router.replace({ name: 'mediaInventory', query: send })

      this.getMedia()
      this.inventoryList()
    },
    /* 페이지네이션 클릭시 */
    paginationClick (page) {
      this.vModel.page = page
      let send = {
        page: page
      }
      this.$router.replace({ name: 'mediaInventory', query: send })
      this.inventoryList()
    },
    /* 렌더링 관리 클릭시 */
    goRendering (v) {
      this.$router.push({ name: 'mediaRender', params: { media_id: this.$route.params.media_id, inventory_id: v.key } })
    },
    /* 인벤토리 수정 클릭시 */
    goEdit (v) {
      this.$router.push({ name: 'mediaInventoryEdit', params: { media_id: this.$route.params.media_id, inventory_id: v.key } })
    },
    /* 인벤토리 신규등록 클릭시 */
    createInventory () {
      this.$router.push({ name: 'mediaInventoryRegister', params: { media_id: this.$route.params.media_id } })
    },
    /* 인벤토리 활성화하기 */
    restoreInventory (val) {
      if (confirm('활성화 하시겠습니까?')) {
        let media = this.$route.params.media_id
        this.xhttp({
          url: media + '/inventory/' + val.key + '/restore',
          method: 'put',
          data: null
        }).then((response) => {
          if (response.status === 200) {
            this.response.listDelete = response.data.data
            this.snack('success', '활성화 완료되었습니다.')
            this.inventoryList()
          }
        }).catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            this.$router.push({ 'name': 'UserLogin' })
          } else {
            this.snack('warning', '관리자에게 문의 바랍니다.')
          }
        })
      }
    },
    /* 인벤토리 비활성화하기 */
    deleteInventory (val) {
      if (confirm('비활성화 하시겠습니까?')) {
        let media = this.$route.params.media_id
        this.xhttp({
          url: media + '/inventory/' + val.key,
          method: 'delete',
          data: null
        }).then((response) => {
          if (response.status === 200) {
            this.response.listDelete = response.data.data
            this.snack('success', '비활성화 완료되었습니다.')
            this.inventoryList()
          }
        }).catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            this.$router.push({ 'name': 'UserLogin' })
          } else {
            this.snack('warning', '관리자에게 문의 바랍니다.')
          }
        })
      }
    },
    /* 인벤토리 리스트 */
    inventoryList () {
      let send = {
        page: this.$route.query.page,
        resolution: 'lo',
      }
      let media = this.$route.params.media_id
      this.xhttp({
        url: '/' + media + '/inventory',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.list = response.data.data
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    getMedia () {
      let send = {
        resolution: 'lo',
      }

      this.xhttp({
        url: '/media/' + this.$route.params.media_id,
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.media = response.data.data.media
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    }
  },
  computed: {
    resultInventory () {
      if (this.response.list) {
        return this.response.list
      } else {
        return false
      }
    }
  },
}
</script>

<style>
  #InventoryList table tbody .tableTitle {
    cursor: pointer;
  }
  #InventoryList table tbody .tableTitle:hover {
    text-decoration: underline;
  }
</style>
